@tailwind base;
@tailwind components;
@tailwind utilities;

/* Fix line height inconsistencies between Blink and Gecko */
@-moz-document url-prefix() {
    body {
        line-height: 1.2;
    }
}

@layer base {
    html,
    body {
        @apply font-sans text-black bg-cream;
    }
}

@-moz-document url-prefix() {
    body {
        line-height: 1.2;
    }
}

@layer components {
    /* Heading styles */

    .display {
        @apply uppercase font-tusker text-mobile-display md:text-desktop-display;
    }
    .h1 {
        @apply uppercase font-tusker text-mobile-h1 md:text-desktop-h1;
    }
    .h2 {
        @apply text-mobile-h2 md:text-desktop-h2;
    }
    .h3 {
        @apply text-mobile-h3 md:text-desktop-h3;
    }
    .block-spacing {
        @apply gap-3 md:gap-5
    }
}
