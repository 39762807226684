@font-face {
    font-family: 'tusker';
    src: url('../public/fonts/tusker.woff2') format('woff2'),
        url('../public/fonts/tusker.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'flexa';
    src: url('../public/fonts/GT-Flexa-Standard-Regular.woff2') format('woff2'),
        url('../public/fonts/GT-Flexa-Standard-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'flexa';
    src: url('../public/fonts/GT-Flexa-Standard-Regular-Italic.woff2')
            format('woff2'),
        url('../public/fonts/GT-Flexa-Standard-Regular-Italic.woff')
            format('woff');
    font-weight: normal;
    font-style: italic;
}
@font-face {
    font-family: 'flexa';
    src: url('../public/fonts/GT-Flexa-Standard-Bold.woff2') format('woff2'),
        url('../public/fonts/GT-Flexa-Standard-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}
@font-face {
    font-family: 'flexa';
    src: url('../public/fonts/GT-Flexa-Standard-Bold-Italic.woff2')
            format('woff2'),
        url('../public/fonts/GT-Flexa-Standard-Bold-Italic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
}
